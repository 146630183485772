import { getgroup } from "@/api/index/index";
export default {
	data: () => {
		return {
            rightarr:'',
            ydata:[],
            projectInfo:{},
            projactNanme:'热风炉',
            pickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }],
              disabledDate(date) {
                return date.getTime() > Date.now() 
              }
            },
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	computed: {
	},
	methods: {

          // 获取折线图数据
    getInfo() {
        let params;
        if (this.start_time) {
          params = {
            device: this.projectData.device,
            mark: this.mark,
            name: this.name,
            node: this.nodeList,
            end_time: this.end_time,
            start_time: this.start_time,
            cp_type: this.projectData.type,
          };
        } else {
          params = {
            device: this.projectData.device,
            mark: this.mark,
            name: this.name,
            node: this.nodeList,
            cp_type: this.projectData.type,
          };
        }
        getgroup(params)
          .then((res) => {
            if(res.data.x == 0){
              this.$message.error('暂未连接现场，无法获得现场数据');
              clearInterval(this.timer);
            }
            let hisdata = {
              dataZoom: {
              },
              dataView: {
                readOnly: false,
                optionToContent : function(option) {
                  var axisData = option.xAxis[0].data;
                  var series = option.series;
                  var tdHeaders = '<td style="border:1px solid #ccc;border-bottom:0;border-right:0;padding:0 10px;background: #f5f7fa;">时间</td>'; //表头
                  series.forEach(function(series) {
                      tdHeaders += '<td style="border:1px solid #ccc;border-bottom:0;border-right:0;padding:0 10px;background: #f5f7fa;">' + series.name + '</td>'; //组装表头
                  });
                     var table = '<div class="addtable"><table border="0" style="text-align:center;margin:10px;border-right:1px solid #ccc;border-bottom:1px solid #ccc;" cellspacing="0" cellpadding="0"><tbody><tr>' + tdHeaders + '</tr>';
                     var tdBodys = ''; //数据
                      for (var i = 0, l = axisData.length; i < l; i++) {
                          for (var j = 0; j < series.length; j++) {
                             tdBodys += '<td style="border:1px solid #ccc;border-bottom:0;border-right:0"> ' + series[j].data[i] + '</td>'; //组装表数据
                         }
                         table += '<tr ><td style="border:1px solid #ccc;border-bottom:0;border-right:0"> <div   class="addar" style="width:100px;height:40px;line-height:40px">' + axisData[i] + '</div></td>' + tdBodys + '</tr>';
                         tdBodys = '';
                     }
                      table += '</tbody></table></div>';
                      return table;
                 }
              },
             
              restore: {},
              saveAsImage: {}
            }
            this.loading = false;
            this.xAxis = res.data.x;
            this.echartData = res.data.series;
            this.option = {
              title: {
                text: "",
              },
         tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            appendToBody:'html',
            className: 'echarts-tooltip echarts-tooltip-dark',
            formatter:(params) =>{
                this.rightarr = params
                }
                },
              color: this.echartcolor,
              legend: {
                data: this.echartData.length == 1 ? this.name1 : this.name,
              },
              grid: {
                left: "10%",
                right: "13%",
                bottom: "10%",
                containLabel: true,
              },
              toolbox: {
              feature: this.active !='-1' ? {} : hisdata,
              top:20
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                 axisTick: {
                     alignWithLabel: true
                 },
                  axisLine: {
                    onZero: false,
                    lineStyle: {
                    }
                  },
 
                data: this.xAxis,
              },
              yAxis: this.ydata,
              series: this.echartData,
              dataZoom: [
                {
                    //默认为滚动条型数据区域缩放组件
                  show: true,
                  xAxisIndex: [0],
                  start: 100,
                  end: 0,
                  // 开始位置的数值
                  startValue: 100,
                  // 结束位置的数值
                  endValue: 0,
                }
              ],
            };
          })
          .catch((err) => {
            this.loading = false;
            // this.$message.error('数据获取失败');
          });
      },
          // 曲线图
    drawLine() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(
          document.getElementById("rts_Chart"),
          "dark"
        );
        this.myChart = myChart;
        // 绘制图表
        this.myChart.setOption(this.option);
        this.myChart.on('click',function(params){
         
            // console.log(this.rightarr)
        })
      },
	},
}
