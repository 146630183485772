<template>
    <div class="trendGroup">
        <div class="fristcon-tit">
        {{projectData.device}}_趋势组图
      </div>
      <div class="trendGroup-cen flex" v-if="haveGroup">
           <div class="trendGroup-cen-left">
           <div id="rts_Chart" v-loading="loading" :option="option"></div>
         </div>
         <div class="fristcon-cen-right">
             <div class="typedata">
                  <div class="typedatabox typedatabox1"
                  v-for="(item, index) in echartData"
				            :key="index"
                    :style="{color:echartcolor[index]}"
                  >
                <div>Y[{{rightarr[index] ? rightarr[index].data : item.data[item.data.length - 1]}}]</div>
                <div>Y[{{item.name}}]</div>
              </div>
             </div>
         </div>
      </div>
      <div class="trendGroup-cen1" v-if="!haveGroup">
       <div>暂无预案</div>
      </div>
      <div class="trendGroup-bot flex align-cen" v-if="haveGroup">
            <div class="fristcon-cen-btn">
        <div class="btn-f flex">
          <div class="btn-f-btn" @click="serchDt(10)" :class="{current:active == 10}">{{dtname}}</div>
           <div class="btn-f-btn" @click="serchHis(72,6)" :class="{current:active == 6}">前翻一页</div>
            <div class="btn-f-btn" @click="serchHis(36,7)" :class="{current:active == 7}">前翻半页</div>
             <div class="btn-f-btn" @click="serchHis(24,8)" :class="{current:active == 8}">后翻半页</div>
              <div class="btn-f-btn" @click="serchHis(12,9)" :class="{current:active == 9}">后翻一页</div>
        </div>
      </div>
        <div class="row4 flex align-cen">
             <div class="time-tit">指定时间：</div>
              <el-date-picker
              v-model="value2"
              type="datetimerange"
              :picker-options="pickerOptions"
             range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
              >
            </el-date-picker>
            <div class="row4-btn" @click="sech">查询</div>
           </div>
              <div class="condition-time ma-auto flex">
        <div @click="serchHis(1,0)" :class="{current:active == 0}">1h</div>
        <div @click="serchHis(2,1)" :class="{current:active == 1}">2h</div>
        <div @click="serchHis(4,2)" :class="{current:active == 2}">4h</div>
        <div @click="serchHis(8,3)" :class="{current:active == 3}">8h</div>
        <div @click="serchHis(24,4)" :class="{current:active == 4}">24h</div>
        <div @click="serchHis(72,5)" :class="{current:active == 5}">72h</div>
      </div>
      </div>
      <div class="trendGroup-bot1 flex justify-content-cen">
         <div class="trendGroup-bot1-btn"
         v-for="(item,index) in btnList"
         :key="item.id"
         @click="toPage(index,item)"
         :class="{active:flag == index}"
         >
           {{item.name}}
         </div>
      </div>
      <!-- 组图选择弹框 -->
      <el-dialog
          title=""
          :visible.sync="centerDialogVisible"
          :show-close="false"
          custom-class="groupD"
          :before-close="handleClose"
          center>
          <div class="dialog1" v-if="flag == 0">
             <div class="flex align-cen flexb "
             v-if="tableData.length !=0"
             >
               <el-tooltip placement="top"
                 v-for="(item,index) in tableData"  :key="index"
               >
                 <div slot="content">{{item.title}}</div>
            <el-radio v-model="radio" 
            class="flex align-cen"
            :label="item.id"
           
            >{{item.title}}</el-radio>
             </el-tooltip>
          </div>
          <div class="groupD-btn flex align-cen" v-if="tableData.length !=0">
            <div class="confirm btn" @click="groupcheck">确定</div>
            <div class="cancle btn" @click="handleClose">取消</div>
          </div>
         <div class="groupd-emtry" v-if="tableData.length ==0" style="padding-top:6vh">
            暂无预案，请先保存~~
          </div>
          </div>
          <div class="dialog1" v-if="flag == 1">
            <div class="flex align-cen flexb ">
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" class="flex align-cen" style="flex-wrap: wrap;justify-content:left;">
            <el-checkbox v-for="(item,index) in typedata" :label="item" :key="index" class="flex align-cen">{{item.mark}}</el-checkbox>
            
          </el-checkbox-group>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="allcheck flex align-cen"
        v-if="typedata.length !=0"
        >
          全选
          </el-checkbox>
          </div>
           <div class="groupD-btn flex align-cen" v-if="typedata.length !=0">
            <div class="confirm btn" @click="addPlan">保存预案</div>
            <div class="cancle btn" @click="deletVar">删除</div>
          </div>
          <div class="groupd-emtry" v-if="typedata.length ==0">
            暂无变量，双击选择需要趋势组图的变量~~
          </div>
          </div>
           <div class="dialog1 dialog2" v-if="flag == 2">
             <el-table
                :data="tableData"
                style="width: 100%;"
                height="22vh"
               empty-text="暂无预案～～"
                fixed
                >
                <el-table-column
                   center
                  prop="title"
                  label="原名称"
                  >
                </el-table-column>
                <el-table-column
                  prop=""
                  label="修改名称"
                   center
                  >
                  <template slot-scope="scope">
                    <el-button type="info" size="small"
                    @click="cellEdit(scope.$index, scope.row)"
                    >修改</el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="删除"
                   center
                  >
                    <template slot-scope="scope">
                    <el-button type="danger" size="small" @click="cellDelet(scope.$index, scope.row)">删除</el-button>
                  </template>
                </el-table-column>
            </el-table>
           </div>
        </el-dialog>
    </div>
</template>
<script>
import index from "./_index/index.js";
import { planShow } from "@/api/index/index"  //获取预案
import { addPlan } from "@/api/index/index"   // 添加预案
import { editPlan } from "@/api/index/index"  //修改预案名
import { deletPlan } from "@/api/index/index" //删除预案
export default {
   props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data() {
      return {
      tableData:[
      ],
      haveGroup:false,
      plantit:'',
      radioList:[],
      checkAll: false,
      isIndeterminate: false,
      checkedCities:[],
      radio:'',
      centerDialogVisible:false,
      flag:-1,
      Lkname:'',
      Hhistoryname:'',
      Hnode:'',
      isHshow:false,
      historyname:'',
      node:'',
      titname:'',
      typedata:[],
       xAxis: [],
      echartData: [],
      legendData: [],
      timer: null,
      myChart: "",
      loading: true,
      echartcolor: [],
      end_time: "",
      start_time: "",
      scaleHeight: "",
      name: [],
      name1: [],
      mark: [],
      nodeList: [],
      option:'',
      value2: '',
      havetrue:'',
      current:'current',
      active:10,
      now:true,
      dtname:'实时趋势',
      btnList:[
        {
          name:'选择组图预案',
          id:1
        },{
          name:'组图变量池',
          id:2
        },{
          name:'我的预案',
          id:3
        }

      ],
      UserInfo:[],
      projectData:{
        type:'',
        device:''
      }
      }
    },
     mixins: [index],
        watch: {
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.loading = false;
            this.myChart.setOption(newVal, true);
          } else {
            this.loading = false;
            this.myChart.setOption(oldVal);
          }
        } else {
          this.loading = false;
          this.drawLine();
        }
      },
    },
      rightarr1: {
      handler(newVal, oldVal) {
    
        if (this.rightarr) {
          if (newVal) {
            this.rightarr(newVal, true);
          } else {
            this.rightarr(oldVal);
          }
        }
      },
    },
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    // rightarr
  },
   created(){
       this.typedata = JSON.parse(localStorage.getItem("spotArr")) || [];
       this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
       this.projectData = JSON.parse(localStorage.getItem("deviceType"));
       this.getplanShow()
 
    },
    mounted(){
      if(this.haveGroup){
         this.timer = setInterval(() => {
              setTimeout(this.getInfo(), 0);
            }, 1000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {           
                clearInterval(this.timer);                                    
            })
      }
     
    },
    methods:{
      // 组图选择
      groupcheck(){
        let array1 = this.tableData
       let checkArr=array1.filter(item=> item.id === this.radio) 
       this.publicEch(checkArr)
       this.getInfo()
       this.flag = -1
       this.centerDialogVisible = false
      },
      // 删除预案
      cellDelet(index,row){
          this.$confirm('此操作将删除该预案, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            deletPlan({
              id:row.id,
              })
                .then(res => {
                   this.$message.success('已删除');
                 this.getplanShow()
              })
              .catch(err => {
                this.$message.error('删除失败');
              })
        })
      },
      // 编辑预案
      cellEdit(index,row){
           this.$prompt('修改预案名', row.title, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[A-Za-z0-9-_\u4e00-\u9fa5]{2,10}$/,
          inputErrorMessage: '只能输入2-10个字符',
          inputValue:row.title,
        }).then(({ value }) => {
          this.plantit = value
             editPlan({
              id:row.id,
              account:row.account,
              title:this.plantit,
              post_json:row.post_json
              })
                .then(res => {
                 if(res == 1){
                   this.$message.success('修改成功');
                 this.getplanShow()
                 }
              })
              .catch(err => {
                this.$message.error('修改失败');
              })
        })
      },
      // 添加预案
      addPlan(){
        if(this.checkedCities.length == 0){
          return this.$message.error('请先选择变量');
        }
        // 组装选中数组
        let post_json = {}
        let sendmark = []
        let sendnode = []
         for (let i in this.checkedCities) {
          if(i <=9){
           sendmark.push(this.checkedCities[i].mark)
           sendnode.push(this.checkedCities[i].node)
          }
         }
         post_json["mark"] = sendmark
         post_json["node"] = sendnode
          this.$prompt('请输入预案名', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
           inputPattern: /^[A-Za-z0-9-_\u4e00-\u9fa5]{2,10}$/,
          inputErrorMessage: '只能输入2-10个字符',
          
        }).then(({ value }) => {
          this.plantit = value
             addPlan({
              account:this.UserInfo[1],
              title:this.plantit,
              post_json:post_json,
              cp_type:this.projectData.type
              })
                .then(res => {
                 this.$message.success(res.msg);
                  this.centerDialogVisible = false;
                  return
                  // localStorage.removeItem('spotArr')
                  location.reload();
              })
              .catch(err => {
                this.$message.error('添加失败');
              })
        })
      },
            // 删除所选变量
      deletVar(){
        if(this.checkedCities.length == 0){
          return this.$message.error('请先选择变量');
        }
        let array1 = this.typedata
        let array2 = this.checkedCities
        // 过滤两个数组中对象mark值相等的项
        let add=array1.filter(item=>!array2.some(ele=>ele.mark===item.mark)) 
        this.typedata = add
       localStorage.setItem("spotArr", JSON.stringify(add))
      },
      // 弹窗关闭
      handleClose(done){
        this.flag = -1;
        this.centerDialogVisible=false,
        done();
      },
      // 获取预案
        getplanShow(){
            planShow({
              account:this.UserInfo[1],
              cp_type:this.projectData.type
              })
                .then(res => {
                 if(res.length !=0){
                  this.haveGroup = true
                  // this.$message.success('默认展示第一条预案：' + res[0].title);
                  this.tableData = res
                  this.radio = res[0].id
                  this.publicEch(res)
                   this.getInfo()
                 }else{
                 
                   this.centerDialogVisible=false,
                   this.loading = false
                   this.haveGroup = false
                   this.tableData = res
                   if(this.typedata.length ==0){
                      this.$message.error('暂无预案，请先双击点名添加');
                   }else{
                      this.$message.error('暂无预案，请先在组图流量池里选择保存');
                   }
                  
                 }
              })
              .catch(err => {
                this.$message.error('获取预案失败');
              })
        },
        // 曲线图组合数据公用方法
          publicEch(res){
              this.mark = res[0].post_json.mark
                  this.nodeList = res[0].post_json.node
                  this.ydata = []
                  this.name = []
                  for (let i in res[0].post_json.mark) {
                      this.name.push(res[0].post_json.mark[i] + "_WF")
                    }
                  // if(this.projectData.type == 'rfl'){
                    
                  // }
                   let coror = ["#1799cd","#0de0ac","#ec163e","#f2c334","#ec24ea","#0dd9e0","#FF6347","#1662ec","#FFC0CB","#E6E6FA"]
                    coror.length = res[0].post_json.mark.length
                    this.echartcolor = coror;
                    let num = 55
                    let yobj
                    for (let k in res[0].post_json.mark) {
                      yobj = {
                        type: "value",
                            name:'',
                            position: k < 5 ? "left" : "right",
                            boundaryGap:[0,'100%'],
                            scale:true,
                            offset: k<5 ? k*num : k*num - 250,
                            axisLine: {
                                show:true,
                                lineStyle: {
                                    color: this.echartcolor[k]
                                }
                            },
                              axisTick:{
                                show:true
                              },
                              axisLabel: {
                                formatter: "{value}",
                              },
                          }
                       this.ydata.push(yobj)
                    }
        },
        handleCheckAllChange(val) {
        this.checkedCities = val ? this.typedata : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.typedata.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.typedata.length;
      },
      // 组图按钮切换
      toPage(i,data){
        let obj = Object.keys(data)
        if(obj.length != 0){
          this.flag = i
          this.centerDialogVisible = true
        }
      },
           // 时间段查询
      sech(){
        this.start_time = this.value2[0];
        this.end_time = this.value2[1];
        this.getInfo();
      },
       // 历史实时
      serchDt(index){
        this.now = !this.now
       
        if(this.now ){
           this.active = index
          this.dtname = '实时趋势'
             this.timer = setInterval(() => {
              setTimeout(this.getInfo(), 0);
            }, 1000);
        }else{
           this.active = '-1'
          this.dtname = '历史趋势'
          this.getInfo()
           clearInterval(this.timer);
        }
      },
          //  查看过去趋势
    serchHis(val,i) {
      this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
      this.start_time = this.$util.getTimeData(val);
      this.active = i
      this.getInfo();
    },
    },
}
</script>
<style lang="scss" scoped>
.addtable{
  table{
    margin: 0 10px;
   
  }
}
.groupd-emtry{
  color: #fff;
  font-size: 1vw;
  text-align: center;
  padding-top: 10vh;
}
.el-main{
  width: 100% !important;
}
.current{
  color: #0ef7ff !important;
  border: 1px solid #0ef7ff;
  box-sizing: border-box;
}
.trendGroup{
  width: 100vw; 
  height: 92vh;
  margin-left: -2.5vw;
  overflow: hidden;
  background-image: linear-gradient(
		#042643, 
		#042643), 
	linear-gradient(0deg, 
		#0b385f 3%, 
		#064468 30%, 
		#005071 57%, 
		#045373 74%, 
		#085575 100%);
	background-blend-mode: normal, 
		normal;
  .fristcon-tit{
    width: 53vw;
    height: 5vh;
    background: url("~@/assets/images/ding1.png") no-repeat center center;
    background-size: cover;
    margin: 3px auto;
    text-align: center;
    line-height: 3.5vh;
    color: #0ef7ff;
    font-size: 3vh;
  }
  .trendGroup-cen1{
    color: #fff;
    width: 100%;
    text-align: center;
    height: 65vh;
    line-height: 65vh;
  }
  .trendGroup-cen{
      margin: 0 1vw;
      .trendGroup-cen-left{
          width: 83vw;
          height: 65vh;
          // background: #fff;
         #rts_Chart{
          width: 100%;
          height: 100%;
          }
      }
      .fristcon-cen-right{
          width: 14vw;
          margin-left: 1vw;
          height: 65vh;
           background: rgba(0,22,36,.2);
      .typedata{
        width: 11vw;
        height: inherit;
        margin-left: 2vw;
        margin-top: 1vh;
       .typedatabox{
         margin-bottom: 2.5vh;
         div{
           	font-family: 'MicrosoftYaHei';
	            font-size: 1vh;
              line-height: 2vh;
              // color: #fff;
         }
       }
      }
      }
  }
  .trendGroup-bot{
      width: 83vw;
      margin-left: 1vw;
        .fristcon-cen-btn{
    // width: 100%;
    .btn-f{
      width: 37vw;
      height: 3vh;
      margin-top: 1vh;
      .btn-f-btn{
        width: 5vw;
        background: rgba(14, 247, 255, 0.2);
        border-radius: 2px;
        color: rgba(14, 247, 255, 0.5);
        text-align: center;
        line-height: 3vh;
        font-size: 1vw;
        margin-left: 2vw;
         cursor: pointer;
      }
      .btn-f-btn:first-child{
        margin-left: 0;
       
      }
    }
  }
   .row4{
     margin-top: 1vh;
     margin-left: 3vw;
        .time-tit{
          color: #0c78a3;
          font-size: 1vw;
        }
        .row4-btn{
          width: 4vw;
          height: 3vh;
           background: rgba(14, 247, 255, 0.2);
           border-radius: 2px;
          color: rgba(14, 247, 255, 0.5);
          text-align: center;
          line-height: 3vh;
          font-size: 1vw;
          margin-left: 10px;
        }
        .el-range-editor.el-input__inner{
          background: #031d30 !important;
          border: 1px solid #064a6d;
          width: 16vw;
          height: 3vh;
         padding: 3px 5px;
         position: relative;
         z-index: 1;
        }
       .el-range__icon{
         line-height: 2vh;
       }
      }
          .condition-time {
        margin-top: 1vh;
      div {
        width: 2vw;
        height: 3vh;
        background-color: rgba(14,247,255,.2);
        border-radius: 2px;
        line-height: 3vh;
        text-align: center;
        font-size: 1vw;
        color: #209caf;
        margin-right: 3px;
        cursor: pointer;
      }
    }
  }
  .trendGroup-bot1{
    margin: 1.5vh 1vw;
    .trendGroup-bot1-btn{
      width: 10vw;
      height: 8vh;
       background: url("~@/assets/images/group_1.png") no-repeat 0 0;
       background-size: 100% 100%;
       line-height: 8vh;
       text-align: center;
       color: #fff;
       font-size: 1vw;
       margin-right: 10vw;
       cursor: pointer;
    }
    .active{
        background: url("~@/assets/images/grouo1_active.png") no-repeat 0 0;
       background-size: 100% 100%;
    }
  }
}
::v-deep{
  .dialog2{
    margin-top: -30px;
    .el-table--enable-row-hover .el-table__body tr:hover>td{
      background: none;
    }
    .el-table{
      background: none;
      tr{
        background: rgba(14, 247, 255, 0.2);
      }
      th{
        background: #057b99;
        border-right:1px solid #021724;
        border-bottom:1px solid #021724;
        .cell{
          color: #99eaff;
          text-align: center;
        }
      }
      td,th{
        border-bottom: none;
        color: #99eaff;
        text-align: center;
        
      }
      td{
        border-right:1px solid #021724;
        border-bottom:1px solid #021724
      }
    }
    .el-table::before{
      content: none;
    }
  }
  .el-range__icon,.el-range__close-icon{
            line-height: 2vh !important;
          }
          .el-range__close-icon{position: absolute;
          z-index: 2;
          right: 0;
          }
          .el-range__icon{display: none;}
          .el-range-input{
             background: #031d30 !important;
             color: #fff;
             width: 42%;
          }
          .el-range-separator{color: #fff;line-height: 2vh;}
     .groupD{
  width: 26vw;
  height: 28vh;
  background-color: rgba(2,23,36,1);
  border-radius: 1vw;
  border: solid 1px #49a3db;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0vh 0 0 -13vw;
  margin-top: -14vh !important;
  position: relative;
  .flexb{
     flex-wrap: wrap;
      margin-top: -5vh !important;
      height: 17vh;
      overflow: auto;
     .el-radio,.el-checkbox{
       color: #057b99;
       margin-top: 2.5vh;
       width: 31.3%;
       margin-right: 0;
       text-align: center;
       justify-content: center;
       .el-radio__label{
        font-size: 1vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
       }
       .el-radio__input{
         .el-radio__inner{
           width: 1.4vw;
           height: 1.4vw;
           border: solid 1px #045266;
             background: none;
         }
       }
     }
     .el-checkbox{
       margin-top: 1vh;
       width: auto;
       margin-right: 1vw;
       .el-checkbox__label{
         font-size: 1vw;
       }
        .el-checkbox__input{
         .el-checkbox__inner{
           width: 1.4vw;
           height: 1.4vw;
           border: solid 1px #045266;
          background: none;
          border-radius: 100%;
         }
       }
     }
     .el-checkbox__input.is-checked .el-checkbox__inner{
       border: solid 1px #00cbff !important;
     }
       .el-checkbox__input.is-checked .el-checkbox__inner::after{
         width: 1.2vw;
         height: 1.2vw;
         background-color: #00cbff;
        border-radius: 100%;
        // left: 0;
        border: 0 !important;
        left: 0.01vw;
        top: 0.05vw;
       }
        .el-checkbox__label{
         color: #057b99 !important;
       }
    //  .el-checkbox:first-child{margin-left: 0;}
     .el-radio__input.is-checked .el-radio__inner{
       border: solid 1px #00cbff !important;
     }
       .el-radio__input.is-checked .el-radio__inner::after{
         width: 1.2vw;
         height: 1.2vw;
         background-color: #00cbff;
         margin-left: 0.007vw;
         margin-top: 0.007vw;
       }
       .el-radio__label {
         color: #057b99;
       }
       
  }
  .allcheck{
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{
    content: none !important;
  }
  .el-checkbox__input .el-checkbox__inner::after{
    // content: none;
    width: 0;
    border: 0;
  }
  .groupD-btn{
    position: absolute;
    bottom: 3vh;
    width: 100%;
    justify-content: center;
    left: 50%;
    margin-left: -50%;
    .btn{
      width: 8vw;
      height: 5vh;
      border-radius:2px;
      background-color: #052d3d;
      font-size: 1vw;
      line-height: 5vh;
      text-align: center;
      color: #057b99;
      cursor: pointer;
    }
    .confirm{
      background: #057b99;
      color: #99eaff;
      margin-right: 2vw;
    }
  }
}
}
</style>